<script>
    import ltree from '../ltree/ltree.vue';

    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'item_open'],
        components: {ltree},
        data() {
            return {
                first_id: 0,
                item_list: [],
                active_open: [],
                old_item_list: [],
                menu_arr: [],
                all_menu: [],
                tree: [],
                active: false,
                form: {
                    laiyuan: 1,
                },
                sampletab: 1,
                Additemlist: false,
                right_check_all: false,
                left_check_all: false,
                choose_arr: [],
                leftformInline: {
                    item_name: '',


                },
                rightformInline: {
                    item_name: '',

                }
            }
        },
        mounted() {
            this.GMethod_category_alllist();
            this.tree_show = true
        },
        methods: {
            clearsearch() {
                this.leftformInline = {
                    item_name: '',
                }
                this.getjiancexiangmulistup()
            },
            GMethod_category_alllist() {
                this.$sa0.post({
                    url: this.$api('Detitemalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tree = this.makeList(response.data.list, true);
                            if (this.item_open) {
                                this.active_open = this.findFather(this.deepToArr(response.data.list), [this.item_open])
                            } else {
                                this.active_open = this.findFather(this.deepToArr(response.data.list), [])
                            }
                            this.$refs['ltreeref'].active_arr = this.active_open
                            this.getjiancexiangmulist(this.active_open[this.active_open.length - 1])
                            this.menuarrmaker()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            deepToArr(list, arr = []) {
                let l = arr
                let c = []
                list.map((item) => {
                    l.push({
                        'id': Number(item.id),
                        "type": item.type,
                        "pid": item.pid,
                    })
                    if (item.child.length > 0) {
                        c = [
                            ...c,
                            ...item.child,
                        ]
                    }
                })
                if (c.length > 0) {
                    return this.deepToArr(c, l)
                } else {
                    return l
                }
            },
            findFather(list, arr) {
                let res = false
                list.map((item) => {
                    if (arr.length === 0) {
                        if (item.type === 1) {
                            if (!res) res = this.findFather(list, [item.id])
                        }
                    } else {
                        if (item.id === arr[0]) {
                            if (item.pid !== 0) {
                                arr.unshift(item.pid)
                                res = this.findFather(list, arr)
                            } else {
                                res = arr
                            }
                        }
                    }
                })
                return res
            },
            makeList(list) {
                return list.map((item) => {
                    if (item.type === 1 && this.first_id === 0) {
                        this.first_id = Number(item.id)
                    }
                    return {
                        'id': Number(item.id),
                        "type": item.type,
                        "name": item.name,
                        "children": item.child.length === 0 ? [] : this.makeList(item.child),
                        "content": {
                            "pid": item.pid,
                        }
                    }
                })
            },

            menuarrmaker() {
                this.all_menu = []
                this.menu_arr = []
            },
            mark_list() {
                let l = []
                this.choose_arr.map((item) => {
                    l.push(item.id)
                })
                let li = []
                this.old_item_list.map((item) => {
                    if (l.indexOf(item.id) === -1) {
                        li.push({
                            ...item,
                            check: false,
                        })
                    }
                })
                this.item_list = li
                this.checkLeftActive()
            },
            pullItem() {
                let l = []
                this.old_item_list.map((item) => {
                    l.push(item.id)
                })
                let last_ = []
                this.choose_arr.map((item) => {
                    if (item.check) {
                        if (l.indexOf(item.id) !== -1) {
                            this.item_list.push({
                                ...item,
                                check: false,
                            })
                        }
                    } else {
                        last_.push(item)
                    }
                })
                this.choose_arr = last_

                this.left_check_all = false
                this.right_check_all = false
            },
            pullAll() {
                let l = []
                this.old_item_list.map((item) => {
                    l.push(item.id)
                })
                this.choose_arr.map((item) => {
                    if (l.indexOf(item.id) !== -1) {
                        this.item_list.push({
                            ...item,
                            check: false,
                        })
                    }
                })
                this.choose_arr = []

                this.left_check_all = false
                this.right_check_all = false
            },
            pushItem() {
                let l = []
                this.choose_arr.map((item) => {
                    l.push(item.id)
                })
                let last_ = []
                this.item_list.map((item) => {
                    if (item.check) {
                        if (l.indexOf(item.id) === -1) {
                            this.choose_arr.push({
                                ...item,
                                check: false,
                            })
                        }
                    } else {
                        last_.push(item)
                    }
                })
                this.item_list = last_

                this.left_check_all = false
                this.right_check_all = false
            },
            pushAll() {
                let l = []
                this.choose_arr.map((item) => {
                    l.push(item.id)
                })
                this.item_list.map((item) => {
                    if (l.indexOf(item.id) === -1) {
                        this.choose_arr.push({
                            ...item,
                            check: false,
                        })
                    }
                })
                this.item_list = []
                this.left_check_all = false
                this.right_check_all = false
            },
            getjiancexiangmulistup() {
                this.getjiancexiangmulist(this.active_open[this.active_open.length - 1])
            },
            getjiancexiangmulist(det_item_id) {
                // 样品管理：检测项目列表
                this.$sa0.post({
                    url: this.$api('样品管理：检测项目列表'),
                    data: {
                        item_name: this.leftformInline.item_name,
                        method_name: '',
                        status: 1,
                        det_item_id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.old_item_list = response.data.result.list
                            this.mark_list()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            treeCallback(item) {
                let id = 0
                this.active_open = item.map((ii) => {
                    if (ii.type === 1) {
                        id = ii.id;
                    }
                    return Number(ii.id)
                })
                if (id) this.getjiancexiangmulist(id)
            },
            onClose() {
                this.close();
            },
            // 定义 关闭抽屉时的 函数
            onCloseadditemlistDrawer() {
                this.Additemlist = false;
            },
            // 定义 打开抽屉时的 函数
            showsadditemlistDrawer() {
                this.Additemlist = true;
            },
            leftItemClick(key) {
                this.$set(this.item_list[key], 'check', !this.item_list[key].check)
                this.checkLeftActive()
            },
            rightItemClick(key) {
                this.$set(this.choose_arr[key], 'check', !this.choose_arr[key].check)
                this.checkRightActive()
            },

            checkLeftAll() {
                this.item_list.map((item, key) => {
                    this.$set(this.item_list[key], 'check', this.left_check_all)
                })
            },
            checkRightAll() {
                this.choose_arr.map((item, key) => {
                    this.$set(this.choose_arr[key], 'check', this.right_check_all)
                })
            },

            checkLeftActive() {
                let do_ = true
                if (this.item_list.length === 0) do_ = false
                this.item_list.map((item) => {
                    if (!item.check) do_ = false
                })
                this.left_check_all = do_
            },
            checkRightActive() {
                let do_ = true
                if (this.choose_arr.length === 0) do_ = false
                this.choose_arr.map((item) => {
                    if (!item.check) do_ = false
                })
                this.right_check_all = do_
            },
        }
    }
</script>
<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="添加检测项目" width="100%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div class="addsamplecontainer">
                <div class="sidebartree">
                    <div class="treetitle">
                        样品类别列表
                    </div>
                    <div class="treeborder">
                        <ltree ref="ltreeref" w="230px" ot="only" :dt="0" :it="1" :data="tree" all="all1" :all_menu="all_menu" :menu="menu_arr" :callback="treeCallback"></ltree>
                    </div>
                </div>
                <div>
                    <div class="addsamplewrap">
                        <div class="addsamplewrapleft">
                            <div>
                                <a-form-model layout="inline" @submit.native.prevent>
                                    <a-form-model-item label="">
                                        <a-input v-model="leftformInline.item_name" placeholder="请输入项目名称" style="width: 200px" @keyup="getjiancexiangmulistup"></a-input>
                                    </a-form-model-item>
                                    <a-form-model-item label="">
                                        <a-button type="primary" class="" @click="clearsearch()"> 清空搜索</a-button>
                                    </a-form-model-item>
                                </a-form-model>
                            </div>
                            <table class="layui-table" lay-size="sm" style="margin-top: 0">
                                <thead>
                                <tr>
                                    <th>
                                        <a-checkbox v-model="left_check_all" @change="checkLeftAll()"></a-checkbox>
                                    </th>
                                    <th width="50">序号</th>
                                    <th>项目名称</th>
                                    <th>检验方法</th>
                                    <th>标准值</th>
                                    <th>单位</th>
                                    <th>检验标准</th>
                                    <th width="50">备注</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,key) in item_list" @click="leftItemClick(key)" style="cursor:pointer;">
                                    <td>
                                        <a-checkbox v-model="item.check" @change="checkLeftActive()"></a-checkbox>
                                    </td>
                                    <td>{{ key + 1 }}</td>
                                    <td>{{ item.item_name }}</td>
                                    <td>{{ item.method_name }}</td>
                                    <td>{{ item.bz_value_str }}</td>
                                    <td>{{ item.factor_unit_name }}</td>
                                    <td>{{ item.check_bz_name }}</td>
                                    <td>{{ item.remark }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="addsamplewrapbtnerer">
                            <div class="addsamplewrapbtner">
                                <div @click="pushAll()" class="csconmid-btn-b">
                                    <a-icon type="double-right"/>
                                </div>
                                <div @click="pushItem()" class="csconmid-btn-b">
                                    <a-icon type="right"/>
                                </div>
                                <div @click="pullItem()" class="csconmid-btn-b">
                                    <a-icon type="left"/>
                                </div>
                                <div @click="pullAll()" class="csconmid-btn-b">
                                    <a-icon type="double-left"/>
                                </div>
                            </div>

                            <div class="addsamplewrapbtnerbottom">
                                <a-button type="primary" @click="onClose()" style="width: 80px; height: 40px !important;">
                                    确认
                                </a-button>
                            </div>
                        </div>

                        <div class="addsamplewrapright">

                            <table class="layui-table" lay-size="sm" style="margin-top: 0">
                                <thead>
                                <tr>
                                    <th>
                                        <a-checkbox v-model="right_check_all" @change="checkRightAll()"></a-checkbox>
                                    </th>
                                    <th>序号</th>
                                    <th>项目名称</th>
                                    <th>检验方法</th>
                                    <th>单位</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,key) in choose_arr" @click="rightItemClick(key)" style="cursor:pointer;">
                                    <td>
                                        <a-checkbox v-model="item.check" @change="checkRightActive()"></a-checkbox>
                                    </td>
                                    <td>{{ key + 1 }}</td>
                                    <td>{{ item.item_name }}</td>
                                    <td>{{ item.method_name }}</td>
                                    <td>{{ item.factor_unit_name }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </a-drawer>


    </div>
</template>
<style scoped>
    .addsamplecontainer{
        width: 100%;
        display: flex;
        }

    .treetitle{
        width: 230px;
        position: absolute;
        top: 80px;
        left: 20px;
        z-index: 99999;
        background: #f9f9f9;
        padding: 0;
        box-sizing: border-box;
        height: 40px;
        text-align: left;
        padding-left: 10px;
        line-height: 40px;
        font-weight: bold
        }

    .treeborder{
        width: 231px;
        border: 1px solid #eeeeee;
        height: calc(100vh - 130px);
        margin-left: -5px;
        border-bottom: none
        }

    .addsamplewrap{
        padding-left: 20px;
        display: flex;
        justify-content: left;
        width: 1500px;
        }

    .w200{
        width: 200px
        }

    .fcb{
        background: #fafcff;
        color: #666
        }

    .w300{
        width: 200px
        }

    .w500{
        width: 500px
        }

    .w50{
        width: 100px
        }

    .text-l{
        text-align: left
        }

    .ml20{
        margin-left: 20px
        }

    .addsamplewrapleft{
        width: 900px;
        height: 700px;
        overflow-y: auto;
        /*border: 1px solid #FAFAFA;*/
        }


    /*滚动条样式*/
    .addsamplewrapleft::-webkit-scrollbar{
        width: 0px;
        }

    .addsamplewrapleft::-webkit-scrollbar-thumb{
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #cccccc;
        }

    .addsamplewrapleft::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: #f9f9f9;

        }


    .addsamplewrapbtner{
        width: 150px;
        height: 200px;
        }

    .addsamplewrapbtnerbottom{
        position: absolute;
        top: 650px;
        left: 50%;
        transform: translate(-50%, -50%);
        }


    .addsamplewrapright{
        width: 550px;
        height: 700px;
        overflow-y: auto;
        /*border: 1px solid #FAFAFA;*/
        padding-top: 40px;
        }


    .addsamplewrapright::-webkit-scrollbar{
        width: 0px;
        }

    .addsamplewrapright::-webkit-scrollbar-thumb{
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #cccccc;
        }

    .addsamplewrapright::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: #f9f9f9;

        }


    .addsamplewrapbtnerer{
        position: relative;
        width: 100px;
        height: 600px;
        text-align: center
        }

    .addsamplewrapbtner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 400px;
        overflow-x: hidden;
        }

    .addsamplewrapbtner .csconmid-btn-b{
        width: 40px;
        height: 40px;
        background: #1890ff;
        color: #ffffff;
        text-align: center;
        line-height: 35px;
        margin: 10px;
        border-radius: 2px;
        cursor: pointer
        }


</style>
