<script>
import ltree from '../ltree/ltree.vue';
import Additemlist from '../additemlist/additemlist';

export default {
  // 接收 显示变量 和 关闭函数
  props: ['visible', 'close'],
  components: {Additemlist},
  data() {
    return {
      all_jianceren_check: false,
      all_shenheren_check: false,
      all_jiaoyanren_check: false,
      all_jianceren: '',
      all_shenheren: '',
      all_jiaoyanren: '',
      caijiren_list: [],
      unsave: [],
      jianceren: [],
      shenheren: [],
      jiaoyanren: [],
      yangpinguize_list: [],
      item_check_all: false,
      choose_arr: [],
      tree: [],
      tab_type: 0,
      active: false,
      task_info: false,
      item_open: 0,
      form: {
        laiyuan: 1,
      },
      sampletab: 1,
      Additemlist: false,
      yangpin_info: {
        id: 0,
        name: '',
        in_number: '',
        yangpincaijileixing: 1,
        yangpinshuliang: 1,
        pick_per: '',
        pick_pers: [],
        other_pick_per: [],
        pick_space: '',
        jing_dot: '',
        wei_dot: '',
        caijidanwei: '',
        songyangren: '',
        laiyangzhuangtai: '',
        xingzhuang: '',
        guige: '',
        baozhuang: '',
        shuliangdanwei: '',
        shengchanriqi: '',
        shixiaoriqi: '',
        yangpinpihao: '',
        yangpinleixing: '',
        remark: '',
      },
      level: app_config.level,
      visible1: false,
      plform: {},
      dataSource: {
        name: [],//样品名称
        pick_space: [],//采集地点
        caijidanwei: [],//送样单位

        songyangren: [],//检验项目
        xingzhuang: [],//检验项目

        baozhuang: [],//样品包装
        guige: [],//检验项目
        yangpinpihao: [],//检验项目
        laiyangzhuangtai: [],//来样状态
        shuliangdanwei: [],//检验项目
        yangpinleixing: [],//检验项目
      },
    }
  },
  mounted() {
    this.mountedDo();
    // this.tree_show = true
  },
  computed: {
    checkShow() {
      let show = this.level === 2 && this.$store.state.admin_info.info.id === 360
      return !show
    },
  },

  methods: {

    getXyClick(){
      this.yangpin_info.jing_dot = '110.46'
      this.yangpin_info.wei_dot = '40.51'
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      );
    },

    onSearch11(searchText) {
      this.$sa0.post({
        url: this.$api('Get_spec_item1'),
        data: {
          search_value: searchText,
          ziduan_param: 'songyangren'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            console.log(999999, response.data.list)
            if (response.data.list.length > 0) {
              this.dataSource.songyangren = response.data.list.filter(item => {
                return item.param_name
              }).map(i => i.param_name)
            } else {
              this.dataSource.songyangren = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    onSearch10(searchText) {
      this.$sa0.post({
        url: this.$api('Get_spec_item1'),
        data: {
          search_value: searchText,
          ziduan_param: 'yangpinleixing'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            console.log(999999, response.data.list)
            if (response.data.list.length > 0) {
              this.dataSource.yangpinleixing = response.data.list.filter(item => {
                return item.param_name
              }).map(i => i.param_name)
            } else {
              this.dataSource.yangpinleixing = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    onSearch9(searchText) {
      this.$sa0.post({
        url: this.$api('Get_spec_item1'),
        data: {
          search_value: searchText,
          ziduan_param: 'yangpinpihao'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {

            console.log(999999, response.data.list)
            if (response.data.list.length > 0) {
              this.dataSource.yangpinpihao = response.data.list.filter(item => {
                return item.param_name
              }).map(i => i.param_name)
            } else {
              this.dataSource.yangpinpihao = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    onSearch8(searchText) {
      this.$sa0.post({
        url: this.$api('Get_spec_item1'),
        data: {
          search_value: searchText,
          ziduan_param: 'shuliangdanwei'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {

            console.log(999999, response.data.list)
            if (response.data.list.length > 0) {
              this.dataSource.shuliangdanwei = response.data.list.filter(item => {
                return item.param_name
              }).map(i => i.param_name)
            } else {
              this.dataSource.shuliangdanwei = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    onSearch7(searchText) {
      this.$sa0.post({
        url: this.$api('Get_spec_item1'),
        data: {
          search_value: searchText,
          ziduan_param: 'guige'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {

            console.log(999999, response.data.list)
            if (response.data.list.length > 0) {
              this.dataSource.guige = response.data.list.filter(item => {
                return item.param_name
              }).map(i => i.param_name)
            } else {
              this.dataSource.guige = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    onSearch6(searchText) {
      this.$sa0.post({
        url: this.$api('Get_spec_item1'),
        data: {
          search_value: searchText,
          ziduan_param: 'baozhuang'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.data.list.length > 0) {
              this.dataSource.baozhuang = response.data.list.filter(item => item.param_name).map(i => i.param_name)
            } else {
              this.dataSource.baozhuang = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    onSearch5(searchText) {
      this.$sa0.post({
        url: this.$api('Get_spec_item1'),
        data: {
          search_value: searchText,
          ziduan_param: 'xingzhuang'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.data.list.length > 0) {
              this.dataSource.xingzhuang = response.data.list.filter(item => item.param_name).map(i => i.param_name)
            } else {
              this.dataSource.xingzhuang = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    onSearch4(searchText) {
      this.$sa0.post({
        url: this.$api('Get_spec_item1'),
        data: {
          search_value: searchText,
          ziduan_param: 'laiyangzhuangtai'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.data.list.length > 0) {
              this.dataSource.laiyangzhuangtai = response.data.list.filter(item => item.param_name).map(i => i.param_name)
            } else {
              this.dataSource.laiyangzhuangtai = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    onSearch1(searchText) {
      this.$sa0.post({
        url: this.$api('Get_spec_item1'),
        data: {
          search_value: searchText,
          ziduan_param: 'name'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.dataSource.name = response.data.list.map(item => item.param_name)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    onSearch2(searchText) {
      this.$sa0.post({
        url: this.$api('Get_spec_item1'),
        data: {
          search_value: searchText,
          ziduan_param: 'pick_space'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // this.dataSource.pick_space = response.data.list.map(item => item.pick_space)
            if (response.data.list.length > 0) {
              this.dataSource.pick_space = response.data.list.filter(item => item.param_name).map(i => i.param_name)
            } else {
              this.dataSource.pick_space = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    onSearch3(searchText) {
      this.$sa0.post({
        url: this.$api('Get_spec_item1'),
        data: {
          search_value: searchText,
          ziduan_param: 'caijidanwei'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.data.list.length > 0) {
              this.dataSource.caijidanwei = response.data.list.filter(item => item.param_name).map(i => i.param_name)
            } else {
              this.dataSource.caijidanwei = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    clickrow(key) {
      this.$set(this.choose_arr[key], 'check', !this.choose_arr[key].check)

    },

    plshowModal() {
      let id = 0
      this.choose_arr.map((item, key) => {
        if (item.check) {
          id = item.id

        }
      })
      if (!id) return layer.msg('请选择项目')
      this.visible1 = true;
    },
    handleOk(e) {
      this.choose_arr.map((item, key) => {
        if (item.check) {
          if (this.all_jianceren != '') {
            this.renChange('jianceren', key, this.all_jianceren)
          }
          if (this.all_shenheren != '') {
            this.renChange('shenheren', key, this.all_shenheren)
          }
          if (this.all_jiaoyanren != '') {
            this.renChange('jiaoyanren', key, this.all_jiaoyanren)
          }
        }
      })
      this.visible1 = false;
      this.all_jianceren = '';
      this.all_shenheren = '';
      this.all_jiaoyanren = '';
    },


    getYangpinItemList() {
      // 样品管理：获取样品检测项目
      this.$sa0.post({
        url: this.$api('样品管理：获取样品检测项目'),
        data: {
          sample_id: this.yangpin_info.id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let list = response.data.list.map((item) => {
              return {
                check_bz_name: item.check_bz_name,
                id: item.check_item,
                jianceren: item.check_per,
                factor_unit_name: item.factor_unit_name,
                item_name: item.item_name,
                bz_value: item.bz_value_str,
                method_name: item.method_name,
                jiaoyanren: item.read_per,
                shenheren: item.shenhe_id,
                check: false,
              }
            })
            let arr_ = this.choose_arr.map((item) => {
              return Number(item.id)
            })
            list.map((item) => {
              let index = arr_.indexOf(item.id)
              if (index === -1) {
                this.choose_arr.push(item)
              } else {
                this.choose_arr[index] = item
              }
            })
            let ll = this.choose_arr
            this.choose_arr = []
            setTimeout(() => {
              this.choose_arr = ll.map((item)=>{

                if (Number(item.jianceren) === 0) item.jianceren = '';
                if (Number(item.jiaoyanren) === 0) item.jiaoyanren = '';
                if (Number(item.shenheren) === 0) item.shenheren = '';
                return item
              })
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    saveCheckItem() {
      // 样品管理：保存检测项目
      this.$sa0.post({
        url: this.$api('样品管理：保存检测项目'),
        data: {
          sample_id: this.yangpin_info.id,
          choose_arr: this.choose_arr,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let msg = `上传：${this.choose_arr.length}条`
            let unsave = response.data.unsave
            let status = response.data.status
            this.unsave = unsave.map((item) => {
              return Number(item.id)
            });
            let del_ = response.data.del
            if (this.choose_arr.length - unsave.length - status.length > 0) {
              msg += `，成功保存：${this.choose_arr.length - unsave.length - status.length}条`
            }
            if (unsave.length) {
              msg += `，保存失败：${unsave.length}条`
            }
            if (del_.length) {
              msg += `，删除：${del_.length}条`
            }
            if (status.length) {
              msg += `，状态不可修改：${status.length}条`
            }
            layer.msg(msg)
            this.getYangpinItemList()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    jiancerenList() {
      // 样品管理：检测人员列表
      this.$sa0.post({
        url: this.$api('样品管理：检测人员列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.jianceren = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    jiaoyanrenList() {
      // 样品管理：检测人员列表
      this.$sa0.post({
        url: this.$api('样品管理：校验人员列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.jiaoyanren = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    shenherenList() {
      // 样品管理：检测人员列表
      this.$sa0.post({
        url: this.$api('样品管理：审核人员列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.shenheren = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    mountedDo(id = 0) {
      if (id > 0) {
        this.task_info = {id: id}
        // this.$refs['ltreeref'].active_arr = [id]
        this.tab_type = -1
      }
      this.getrenwuweituodanlist()
      this.getCaijiren()
      this.jiancerenList()
      this.jiaoyanrenList()
      this.shenherenList()
      this.yangpinguizeliebiao()

    },
    copyInfo(info) {
      this.task_info = {id: info.task_id}
      this.yangpin_info = info
      this.yangpin_info.id = 0
      // this.$refs['ltreeref'].active_arr = [info.task_id]
    },

    // 获取树列表
    getrenwuweituodanlist() {
      this.$sa0.post({
        url: this.$api('样品管理：任务列表'),
        data: {
          search_value: '',
          yangpin_count: this.tab_type,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.tree = this.makeList(response.data.list);
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    getyangpinbianhao(id) {
      this.$sa0.post({
        url: this.$api('样品管理：获取样品编号'),
        data: {
          id, num: 1
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.yangpin_info.in_number = response.data.serialnumber[0]
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getyangpinbianhaomoren() {
      // 样品管理：获取样品编号
      this.$sa0.post({
        url: this.$api('样品管理：获取样品默认编号'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getyangpinbianhao(response.data.info.id)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    treeCallback(item) {
      this.task_info = item[0]
    },
    makeList(list) {
      return list.map((item) => {
        return {
          'id': Number(item.id),
          "type": 1,
          "name": item.name,
          "children": [],
          "content": false
        }
      })
    },
    onClose() {
      this.close();
    },
    onCloseadditemlistDrawer() {
      this.choose_arr = this.$refs['Additemlist_ref'].choose_arr.map((item) => {
        console.log(JSON.stringify(item))
        if (typeof item.jianceren === 'undefined') item.jianceren = '';
        if (typeof item.jiaoyanren === 'undefined') item.jiaoyanren = '';
        if (typeof item.shenheren === 'undefined') item.shenheren = '';

        if (Number(item.jianceren) === 0) item.jianceren = '';
        if (Number(item.jiaoyanren) === 0) item.jiaoyanren = '';
        if (Number(item.shenheren) === 0) item.shenheren = '';
        console.log(JSON.stringify(item))
        return item
      });
      if (this.$refs['Additemlist_ref'].active_open.length) {
        this.item_open = this.$refs['Additemlist_ref'].active_open[this.$refs['Additemlist_ref'].active_open.length - 1]
      } else {
        this.item_open = 0
      }
      this.Additemlist = false;
    },
    // 定义 打开抽屉时的 函数
    showsadditemlistDrawer() {

      this.Additemlist = true;
      setTimeout(() => {
        this.$refs['Additemlist_ref'].choose_arr = this.choose_arr;
      })
    },
    taskTabClick(count) {
      this.tab_type = count
      this.getrenwuweituodanlist()
    },
    getCaijiren() {
      this.$sa0.post({
        url: this.$api('样品管理：采集人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.caijiren_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    opentask(id) {
      this.task_info = {id: id}
      // this.$refs['ltreeref'].active_arr = [id]
      this.tab_type = -1
    },
    yangpininfo(id) {
      this.$sa0.post({
        url: this.$api('样品管理：样品信息'),
        data: {
          id: id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.yangpin_info = response.data.info
            this.task_info = {id: this.yangpin_info.task_id}
            // this.$refs['ltreeref'].active_arr = [this.yangpin_info.task_id]
            this.getYangpinItemList()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    yangpinguizeliebiao() {
      this.$sa0.post({
        url: this.$api('样品管理：获取编号列表'),
        data: {
          num_type: 1
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.yangpinguize_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    bianji_yangpin() {
      this.$sa0.post({
        url: this.$api('样品管理：编辑样品信息'),
        data: {
          ...this.yangpin_info,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('修改成功')
            this.yangpininfo(this.yangpin_info.id)
            setTimeout(() => {
              this.sampletab = 2
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    create_yangpin() {
      if (this.yangpin_info.id === 0) {
        this.$sa0.post({
          url: this.$api('样品管理：添加样品'),
          data: {
            ...this.yangpin_info,
            task_id: this.task_info.id,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              layer.msg('创建成功')
              this.yangpininfo(response.data.id)
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      } else {
        this.bianji_yangpin()
      }
    },


    create_yangpin1() {
      if (this.yangpin_info.id === 0) {
        this.$sa0.post({
          url: this.$api('样品管理：添加样品'),
          data: {
            ...this.yangpin_info,
            task_id: this.task_info.id,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              layer.msg('创建成功')
              this.yangpininfo(response.data.id)
              setTimeout(() => {
                this.sampletab = 2
              })
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      } else {
        this.bianji_yangpin()
      }
    },

    checkallChange() {
      this.choose_arr.map((item, key) => {
        this.$set(this.choose_arr[key], 'check', this.item_check_all)
      })
    },
    removeItem() {
      let list = []
      this.choose_arr.map((item) => {
        if (!item.check) list.push(item)
      })
      this.choose_arr = list
      this.checkAllItem()
    },
    checkAllItem() {
      let do_ = true
      if (this.choose_arr.length === 0) do_ = false
      this.choose_arr.map((item) => {
        if (!item.check) do_ = false
      })
      this.item_check_all = do_
    },
    renChange(name, key, value) {
      let data = this.choose_arr[key];
      data[name] = value
      this.$set(this.choose_arr, key, data)
    },
    checkitemClick(key) {
      this.$set(this.choose_arr[key], 'check', !this.choose_arr[key].check)
      this.checkAllItem()
    },
    changeAllButtonClick() {
      this.choose_arr.map((item, key) => {
        if (item.check) {
          if (this.all_jianceren_check) {
            this.renChange('jianceren', key, this.all_jianceren)
          }
          if (this.all_shenheren_check) {
            this.renChange('shenheren', key, this.all_shenheren)
          }
          if (this.all_jiaoyanren_check) {
            this.renChange('jiaoyanren', key, this.all_jiaoyanren)
          }
        }
      })
    },
  }
}
</script>
<template>
  <div>
    <a-drawer title="添加样品" width="100%" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div class="addsamplecontainer">
        <!--                <div class="sidebartree">-->
        <!--                    <div class="treetitle">-->
        <!--                        <a-tabs @change="taskTabClick" v-model="tab_type">-->
        <!--                            <a-tab-pane :key="-1" tab="全部"></a-tab-pane>-->
        <!--                            <a-tab-pane :key="0" tab="未绑定样品"></a-tab-pane>-->
        <!--                            <a-tab-pane :key="1" tab="已绑定样品"></a-tab-pane>-->
        <!--                        </a-tabs>-->
        <!--                    </div>-->
        <!--                    <div class="treeborder">-->
        <!--                        <ltree ref="ltreeref" :menu="[]" :all_menu="[]" w="320px" ot="only" :dt="0" :it="1" :data="tree" all="all1" :callback="treeCallback"></ltree>-->
        <!--                    </div>-->
        <!--                </div>-->
        <div class="addsamplewrap">
          <a-tabs type="card" v-model="sampletab">
            <a-tab-pane :key="1" tab="样品信息"></a-tab-pane>
            <a-tab-pane v-if="checkShow" :disabled="yangpin_info.id === 0" :key="2" tab="检验项目"></a-tab-pane>
          </a-tabs>
          <div>

            <div v-if="this.sampletab===1">
              <table class="layui-table">
                <colgroup>
                  <col width="150">
                  <col width="200">
                  <col width="150">
                  <col width="200">
                  <col width="150">
                  <col width="200">
                </colgroup>
                <tbody>
                <tr>
                  <td class="fcb">样品名称</td>
                  <td class="text-l">
                    <a-auto-complete v-model="yangpin_info.name" :data-source="dataSource.name" style="width: 200px"
                                     placeholder="样品名称" :filter-option="filterOption" @focus="onSearch1"/>
                  </td>
                  <td class="fcb">样品编号</td>
                  <td class="text-l">
                    <a-space style="position: relative">
                      <a-input placeholder="样品编号" class="w300" v-model="yangpin_info.in_number"
                               @keyup.enter="getyangpinbianhaomoren"></a-input>
                      <a-dropdown style="position: absolute; right: 15px; top: 8px">
                        <a-icon type="unordered-list" @click="getyangpinbianhaomoren()"
                                style="cursor:pointer; vertical-align: middle"/>
                        <a-menu slot="overlay">
                          <a-menu-item v-for="(ri,rk) in yangpinguize_list" :key="rk" @click="getyangpinbianhao(ri.id)">
                            {{ ri.name }} {{ ri.serialnumber }}
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </a-space>
                  </td>
                  <td class="fcb">样品来源</td>
                  <td class="text-l">
                    <a-radio-group :disabled="yangpin_info.id !== 0" name="radioGroup" :default-value="1"
                                   v-model="yangpin_info.yangpincaijileixing">
                      <a-radio :value="1">
                        自采
                      </a-radio>
                      <a-radio :value="2">
                        送样
                      </a-radio>
                    </a-radio-group>
                  </td>
                  <td class="fcb">样品数量</td>
                  <td class="text-l">
                    <a-input placeholder="样品数量" class="w300" v-model="yangpin_info.yangpinshuliang"></a-input>
                  </td>
                </tr>
                <tr v-if="yangpin_info.yangpincaijileixing===1">
                  <td class="fcb">采集人</td>
                  <td class="text-l">
                    <div>
                      <a-select v-model="yangpin_info.pick_per" placeholder="请选择" class="w300">
                        <a-select-option :value="ci.id" v-for="(ci,ck) in caijiren_list">{{
                            ci.name
                          }}
                        </a-select-option>
                      </a-select>
                    </div>

                    <div class="mt-1">
                      <a-select v-model="yangpin_info.other_pick_per" mode="multiple" style="width: 100%"
                                placeholder="额外采集人" option-label-prop="label">
                        <a-select-option :value="ci.id" v-for="(ci,ck) in caijiren_list" :label="ci.name">
                          {{
                            ci.name
                          }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </td>
                  <td class="fcb">采集地点</td>
                  <td class="text-l">
                    <a-auto-complete v-model="yangpin_info.pick_space" :data-source="dataSource.pick_space"
                                     style="width: 200px" placeholder="采集地点" :filter-option="filterOption"
                                     @focus="onSearch2"/>
                  </td>
                  <td class="fcb">经纬度</td>
                  <td colspan="3" class="text-l">
                    <a-input placeholder="经度" class="w50" v-model="yangpin_info.jing_dot"></a-input>
                    <a-input placeholder="纬度" class="w50 ml20" v-model="yangpin_info.wei_dot"></a-input>
                    <a-button class="ml20" @click="getXyClick()">获取经纬度</a-button>
                  </td>
                </tr>
                <tr v-if="yangpin_info.yangpincaijileixing===2">
                  <td class="fcb">送样人</td>
                  <td class="text-l">


                    <a-auto-complete v-model="yangpin_info.songyangren" :data-source="dataSource.songyangren"
                                     style="width: 200px" placeholder="送样人" :filter-option="filterOption"
                                     @focus="onSearch11"/>
                  </td>

                  <td class="fcb">送样单位</td>
                  <td class="text-l">


                    <a-auto-complete v-model="yangpin_info.caijidanwei" :data-source="dataSource.caijidanwei"
                                     style="width: 200px" placeholder="送样单位" :filter-option="filterOption"
                                     @focus="onSearch3"/>
                  </td>
                  <td class="fcb">采集地点</td>
                  <td class="text-l">


                    <a-auto-complete v-model="yangpin_info.pick_space" :data-source="dataSource.pick_space"
                                     style="width: 200px" placeholder="采集地点" :filter-option="filterOption"
                                     @focus="onSearch2"/>
                  </td>
                  <td class="fcb">来样时状态</td>
                  <td class="text-l">
                    <a-auto-complete v-model="yangpin_info.laiyangzhuangtai" :data-source="dataSource.laiyangzhuangtai"
                                     style="width: 200px" placeholder="状态" :filter-option="filterOption"
                                     @focus="onSearch4"/>
                  </td>
                </tr>
                <tr>
                  <td class="fcb">样品性状</td>
                  <td class="text-l">
                    <a-auto-complete v-model="yangpin_info.xingzhuang" :data-source="dataSource.xingzhuang"
                                     style="width: 200px" placeholder="样品性状" :filter-option="filterOption"
                                     @focus="onSearch5"/>
                  </td>
                  <td class="fcb">样品包装</td>
                  <td class="text-l">
                    <a-auto-complete v-model="yangpin_info.baozhuang" :data-source="dataSource.baozhuang"
                                     style="width: 200px" placeholder="样品包装" :filter-option="filterOption"
                                     @focus="onSearch6"/>
                  </td>
                  <td class="fcb">规格</td>
                  <td class="text-l">
                    <a-auto-complete v-model="yangpin_info.guige" :data-source="dataSource.guige" style="width: 200px"
                                     placeholder="样品规格" :filter-option="filterOption" @focus="onSearch7"/>
                  </td>
                  <td class="fcb">数量单位</td>
                  <td class="text-l">
                    <a-auto-complete v-model="yangpin_info.shuliangdanwei" :data-source="dataSource.shuliangdanwei"
                                     style="width: 200px" placeholder="数量单位" :filter-option="filterOption"
                                     @focus="onSearch8"/>

                  </td>
                </tr>
                <tr>
                  <td class="fcb">生产日期</td>
                  <td class="text-l">
                    <a-date-picker v-model="yangpin_info.shengchanriqi" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                                   class="w300"/>
                  </td>
                  <td class="fcb">失效日期</td>
                  <td class="text-l">
                    <a-date-picker v-model="yangpin_info.shixiaoriqi" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                                   class="w300"/>
                  </td>
                  <td class="fcb">样品批号</td>
                  <td class="text-l">
                    <a-auto-complete v-model="yangpin_info.yangpinpihao" :data-source="dataSource.yangpinpihao"
                                     style="width: 200px" placeholder="样品批号" :filter-option="filterOption"
                                     @focus="onSearch9"/>

                  </td>
                  <td class="fcb">样品类型</td>
                  <td class="text-l">
                    <a-auto-complete v-model="yangpin_info.yangpinleixing" :data-source="dataSource.yangpinleixing"
                                     style="width: 200px" placeholder="样品类型" :filter-option="filterOption"
                                     @focus="onSearch10"/>
                  </td>
                </tr>
                <tr>
                  <td class="fcb">备注</td>
                  <td class="text-l" colspan="7">
                    <a-textarea class="w500" v-model="yangpin_info.remark"></a-textarea>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-if="this.sampletab===2">

              <a-space style="margin-top: 10px; justify-content: space-between; width: 1500px" class="btner">
                <div style="display: flex;">
                  <div class="btnerlist " @click="showsadditemlistDrawer()">
                    <i class="iconfont icon-jia vm" style="font-size: 16px"></i> 添加检测项目
                  </div>
                  <div class="btnerlist" @click="removeItem()">
                    <i class="iconfont icon-yixiaohui" style="font-size: 18px"></i> 移除所选
                  </div>
                  <div class="btnerlist">
                    <i class="iconfont icon-mobanbianzhi" style="font-size: 18px"></i> 自动分配任务
                  </div>
                  <div class="btnerlist" @click="plshowModal">
                    <i class="iconfont icon-shiyanyanzheng" style="font-size: 18px"></i>
                    批量设置检测人/校验人/审核人
                  </div>
                </div>
                <a-modal v-model="visible1" title="批量设置检测人/校验人/审核人" @ok="handleOk" width="400px">
                  <div>

                    <a-form :form="plform" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                      <a-form-item label="检测人">
                        <a-select v-model="all_jianceren" placeholder="请选择" style="width: 200px; margin-left: 10px">
                          <a-select-option value="">暂无</a-select-option>
                          <a-select-option :value="ci.id" v-for="(ci,ck) in jianceren">{{
                              ci.name
                            }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item label="校验人">
                        <a-select v-model="all_jiaoyanren" placeholder="请选择" style="width: 200px; margin-left: 10px">
                          <a-select-option value="">暂无</a-select-option>
                          <a-select-option :value="ci.id" v-for="(ci,ck) in jiaoyanren">{{
                              ci.name
                            }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item label="审核人">
                        <a-select v-model="all_shenheren" placeholder="请选择" style="width: 200px; margin-left: 10px">
                          <a-select-option value="">暂无</a-select-option>
                          <a-select-option :value="ci.id" v-for="(ci,ck) in shenheren">{{
                              ci.name
                            }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-form>

                  </div>
                </a-modal>


              </a-space>

              <table class="layui-table" style="margin-top: -9px; width: 1500px" lay-size="sm">
                <colgroup>
                  <col width="80">
                  <col width="20">
                  <col width="200">
                  <col width="200">
                  <col width="100">
                  <col width="100">
                  <col width="200">
                  <col width="150">
                  <col width="150">
                  <col width="150">
                  <col width="150">
                </colgroup>
                <thead>
                <tr>
                  <th>序号</th>
                  <th>
                    <a-checkbox v-model="item_check_all" @change="checkallChange()"></a-checkbox>
                  </th>
                  <th>项目名称</th>
                  <th>检验方法</th>
                  <th>标准值</th>
                  <th>单位</th>
                  <th>检验标准</th>
                  <th>检测人</th>
                  <th>校验人</th>
                  <th>审核人</th>
                  <th>备注</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,key) in choose_arr" :class="unsave.indexOf(Number(item.id)) !== -1?'save_error':''"
                    @click="clickrow(key)">
                  <td>{{ key + 1 }}</td>
                  <td>
                    <a-checkbox v-model="item.check" @change="checkAllItem()"></a-checkbox>
                  </td>
                  <td>{{ item.item_name }}</td>
                  <td>{{ item.method_name }}</td>
                  <td>{{ item.bz_value }}</td>
                  <td>{{ item.factor_unit_name }}</td>
                  <td>{{ item.check_bz_name }}</td>
                  <td>
                    <a-select v-model="item.jianceren" @change="(e)=>{renChange('jianceren',key,e)}"
                              placeholder="请选择" style="width: 100px;">
                      <a-select-option value="">暂无</a-select-option>
                      <a-select-option :value="ci.id" v-for="(ci,ck) in jianceren">{{ ci.name }}
                      </a-select-option>
                    </a-select>
                  </td>
                  <td>
                    <a-select v-model="item.jiaoyanren" @change="(e)=>{renChange('jiaoyanren',key,e)}"
                              placeholder="请选择" style="width: 100px;">
                      <a-select-option value="">暂无</a-select-option>
                      <a-select-option :value="ci.id" v-for="(ci,ck) in jiaoyanren">{{
                          ci.name
                        }}
                      </a-select-option>
                    </a-select>
                  </td>
                  <td>
                    <a-select v-model="item.shenheren" @change="(e)=>{renChange('shenheren',key,e)}"
                              placeholder="请选择" style="width: 100px;">
                      <a-select-option value="">暂无</a-select-option>
                      <a-select-option :value="ci.id" v-for="(ci,ck) in shenheren">{{ ci.name }}
                      </a-select-option>
                    </a-select>
                  </td>
                  <td>{{ item.remark }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <a-button @click="saveCheckItem()" v-if="sampletab === 2" type="primary"
                      style="width: 100px; height: 34px!important">
              保存
            </a-button>
            <a-button @click="create_yangpin()" v-if="sampletab === 1" :disabled="!task_info && yangpin_info.id === 0"
                      type="primary" style="width: 100px; height: 34px!important">
              保存
            </a-button>
            <a-button @click="create_yangpin1()" v-if="sampletab === 1 && checkShow"
                      :disabled="!task_info && yangpin_info.id === 0"
                      type="primary" style=" height: 34px!important; margin-left: 20px">
              保存并添加检测项目
            </a-button>
          </div>
        </div>
      </div>
    </a-drawer>
    <div v-if="Additemlist">
      <Additemlist :close="onCloseadditemlistDrawer" :item_open="item_open" ref="Additemlist_ref"
                   :visible="Additemlist"></Additemlist>
    </div>

  </div>
</template>

<style scoped>
.save_error {
  background: #ffe9e9;
}

.addsamplecontainer {
  width: 100%;
  display: flex;
}

.treetitle {
  width: 320px;
  position: absolute;
  top: 80px;
  left: 25px;
  z-index: 99999;
  background: #f9f9f9;
  padding: 0;
  box-sizing: border-box;
  height: 40px
}

.treeborder {
  width: 320px;
  border: 1px solid #eeeeee;
  height: calc(100vh - 130px);
  margin-left: 0px;
  border-bottom: none;
  overflow-x: hidden;
}

.addsamplewrap {
  flex: 1;
  padding-left: 20px
}

.w200 {
  width: 200px
}

.fcb {
  background: #fafcff;
  color: #666
}

.w300 {
  width: 200px
}

.w500 {
  width: 500px
}

.w50 {
  width: 100px
}

.text-l {
  text-align: left
}

.ml20 {
  margin-left: 20px
}


table tr {
  cursor: pointer
}


.btner {
  width: 100%;
  background: #f9f9f9;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
  border: 1px solid #eeeeee;
  margin-bottom: 10px;
}

.active {
  background: #96cbf9;
}

.btnerlist {
  cursor: pointer;
  border-right: 1px solid #eeeeee;
  height: 26px;
  line-height: 26px;
  padding: 0 10px
}

.btnerlist :last-child(1) {
  border: none
}

.fcadd {
  color: #66c14c !important;
  font-size: 18px !important;
}

.btnerlist i {
  color: #999999;
  font-size: 14px;
  vertical-align: bottom;
}

.btnerlist:hover {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;

}

.btnerlist:hover i, .btnerlist:hover.fcadd {
  color: #ffffff !important
}

.isthis {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;
}

.isthis i {
  color: #ffffff
}


</style>
