<template>
  <div>
    <div>
      <a-tabs type="card" v-model="tab_active" @change="Changetab()">
        <a-tab-pane key="1" tab="按委托单查看"></a-tab-pane>
        <a-tab-pane v-if="checkShow" key="2" tab="按样品查看"></a-tab-pane>
      </a-tabs>
    </div>
    <div class="samplecontainer" :class="tab_active === '1'? '':'ml20'">
      <div class="sidebar" v-if="tab_active === '1'">
        <div class="sidebartit">
          委托单列表
        </div>
        <div style="padding-top:5px;text-align: center">
          <a-input-search placeholder="搜索" v-model="search_value" style="width: 94%"
                          @keyup="GMethod_category_alllist"/>
        </div>
        <div class="sidebarmain">
          <ltree ref="ltreeref" w="230px" ot="only" :dt="0" :it="1" :data="tree" all="all1" :all_menu="[]" :menu="[]"
                 :callback="treeCallback"></ltree>
        </div>
        <div style="position: absolute; bottom: 0; background: #fff" v-if="size > 20">
          <a-pagination simple v-model="page1" :total="count" :PageSize="size"/>
        </div>
      </div>
      <div class="samplemain">
        <div>
          <a-form-model layout="inline" @submit.native.prevent>
            <a-form-model-item label="">
              <a-input v-model="formInline.name" placeholder="请输入样品编号/名称" style="width: 200px"
                       @keyup="listTypeChange"></a-input>
            </a-form-model-item>
            <a-form-model-item label="样品状态">
              <a-select v-model="formInline.status" placeholder="请选择" style="width: 200px" @change="listTypeChange">
                <a-select-option :value="0">全部</a-select-option>
                <a-select-option :value="1">采集待处理</a-select-option>
                <a-select-option :value="2">采集中</a-select-option>
                <a-select-option :value="3">采集完成</a-select-option>
                <a-select-option :value="4">接收完成</a-select-option>
                <a-select-option :value="8">样品销毁</a-select-option>
                <a-select-option :value="9">确认领取</a-select-option>
                <a-select-option :value="10">拒绝领取</a-select-option>
                <a-select-option :value="11">等待下发采集任务</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-radio-group name="radioGroup" v-model="formInline.type" @change="listTypeChange">
                <a-radio value=''>
                  全部
                </a-radio>
                <a-radio :value="1">
                  自采
                </a-radio>
                <a-radio :value="2">
                  送样
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="">
              <a-button type="danger" class="" @click="clearsearch()"> 清空搜索</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
        <a-space style="margin-top: 10px; background: #ebf8fe; border: none" class="btner">
          <div @click="showsaddampleDrawer()" class="btnerlist " v-if="tab_active === '1'">
            <i class="iconfont icon-jia vm fcadd" style="margin-top: -2px; "></i> 新增样品
          </div>
          <div class="btnerlist" @click="edityangpin()">
            <i class="iconfont icon-htmal5icon28" style="font-size: 18px; "></i> 样品详情
          </div>
          <div class="btnerlist" @click="edityangpin()"><i class="iconfont icon-bianjimian" style=""></i> 编辑样品
          </div>
          <div v-if="checkShow" class="btnerlist" @click="copyInfo()"><i class="iconfont icon-fuzhi1" style=""></i> 复制样品</div>
          <div v-if="checkShow" class="btnerlist" @click="editCheck()">
            <i class="iconfont icon-jianyan2" style="font-size: 18px; vertical-align: -2px;"></i> 设置检验项目
          </div>
          <div v-if="checkShow" class="btnerlist" @click="xiafacaijiclick()"><i class="iconfont icon-xiafa" style=""></i>
            下发采集任务
          </div>
          <div v-if="checkShow" class="btnerlist" @click="tuihuitaskclick()">
            <i class="iconfont icon-tuihui2" style="font-size: 14px"></i> 退回采集任务
          </div>
          <div v-if="checkShow" class="btnerlist" @click="samplejieshou()">
            <i class="iconfont icon-weituokehuxinxiguanli" style="font-size: 18px"></i> 接收样品
          </div>
          <div v-if="checkShow" class="btnerlist" @click="showDrawerReserve()">
            <i class="iconfont icon-weituojianyan-yangbenjianyan2" style="font-size: 16px"></i> 样品留样
          </div>
          <div v-if="checkShow" class="btnerlist" @click="showModal">
            <i class="iconfont icon-tuihui2" style=""></i> 退回样品
          </div>


          <a-modal v-model:visible="visible" title="退回样品原因" @ok="handleOk">
            <p>
              <a-textarea v-model="form.remark" placeholder="请填写退回样品原因"/>
            </p>
          </a-modal>

          <div v-if="checkShow" class="btnerlist" @click="xiafajianyanclick()"><i class="iconfont icon-xiafa"></i> 下发检验任务</div>
          <div v-if="checkShow" class="btnerlist" @click="tuihuijianyantaskclick()">
            <i class="iconfont icon-tuihui2" style="font-size: 14px"></i> 退回检验任务
          </div>
          <div v-if="checkShow" class="btnerlist"><i class="iconfont icon-dayinji"></i> 打印样品标签
            <div class="print-show">
              <div v-for="(item,index) in templateList" :key="item.id"> {{ item.name }}
                <img :src="getImg(item)" style="width:150px">
                <a-button type="primary" @click="PrintTAG(index)">打印</a-button>
              </div>
            </div>
          </div>
          <div v-if="checkShow" class="btnerlist"><i class="iconfont icon-dayinji"></i> 打印交接单</div>
          <div v-if="checkShow" class="btnerlist" @click="showDrawerT()">
            <i class="iconfont icon-liuzhuanxinxi" style="font-size: 14px"></i> 流转记录
          </div>
          <div v-if="checkShow" class="btnerlist">
            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger"
                          @confirm="xiaohuitaskclick()">
              <template slot="title">确认删除该项？</template>
              <i class="iconfont icon-icon_xiaohuis" style="font-size: 16px"></i> 销毁
            </a-popconfirm>
          </div>
        </a-space>
        <div style="margin-top: -19px">
          <table class="layui-table" lay-size="sm">
            <thead>
            <tr>
              <th>
                <a-checkbox v-model="all_active" @change="allChange"></a-checkbox>
              </th>


              <th @click="paixuclickup(4)" style="cursor: pointer; ">
                <span>委托单号</span>
                <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[ordertype === 4 && sortrule === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[ordertype === 4 && sortrule === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
                </div>
              </th>

              <th @click="paixuclickup(3)" style="cursor: pointer;">
                <span>样品类型</span>
                <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[ordertype === 3 && sortrule === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[ordertype === 3 && sortrule === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
                </div>
              </th>
              <th @click="paixuclickup(2)" style="cursor: pointer;">
                <span>样品编号</span>
                <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[ordertype === 2 && sortrule === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[ordertype === 2 && sortrule === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
                </div>
              </th>
              <th @click="paixuclickup(1)" style="cursor: pointer;">
                <span>样品名称</span>
                <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[ordertype === 1 && sortrule === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[ordertype === 1 && sortrule === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
                </div>
              </th>
              <th>样品来源</th>
              <th>样品状态</th>
              <th>是否留样</th>
              <th>到样时间</th>
              <th>送/采样人</th>
              <th>保管人</th>
              <th>检测项目</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,key) in yangpin_list" :key="key" :class="[item.active ? 'active':'']"
                @click="clickrow(key)">
              <td>
                <a-checkbox v-model="item.active" @change="itemActiveChange()"></a-checkbox>
              </td>
              <td>{{ item.task_num }}</td>
              <td>{{ item.yangpinleixing }}</td>
              <td>
                {{ item.in_number }}
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.yangpincaijileixing_turn }}</td>
              <td>
                <span v-if="item.status===8" style="color: #ff2929">{{ item.status_trun }}</span>
                <span v-else> {{ item.status_trun }}</span>
              </td>
              <td>{{ item.liuyang_status === 1 ? '未留样' : '已留样' }}</td>
              <td>{{ item.yangpincaijileixing === 1 ? item.get_time : item.create_time }}</td>
              <td>{{ item.yangpincaijileixing === 1 ? item.caijiren : item.songyangren }}</td>
              <td>{{ item.baoguanren_name }}</td>
              <td style="color:#1890ff">{{ item.jc_item_count }}</td>
            </tr>
            </tbody>
          </table>
          <a-pagination v-model="page" @change="pageChange" v-if="yangpin_list.length" :total="yangpin_count"
                        :pageSize="yangpin_size"/>
        </div>
      </div>
    </div>
    <!--新增样品组件-->
    <div v-if="Addsamplelist">
      <Addsample ref="Addsampleref" :close="onCloseaddsampleDrawer" :visible="Addsamplelist"></Addsample>
    </div>

    <JPrint ref="doPrint"></JPrint>

    <!--时间轴-->
    <div v-if="lishiinfo">
      <TimeLine :info="info" :close="onCloseDrawerT" :visible="drawer_visibleT"></TimeLine>
    </div>

    <!--留样 6-->
    <div v-if="Reserve_info">
      <ReserveSample :Reserve_info="Reserve_info" :close="onCloseDrawerReserve"
                     :visible="drawer_visibleReserve"></ReserveSample>
    </div>
  </div>
</template>

<script>
import ReserveSample from "./reserve-sample/reserve-sample.vue";
import Addsample from './addsample/addsample';
import JPrint from "../../../components/print/JPrint";
import TimeLine from "./time-line/time-line";
import ltree from './ltree/ltree';

export default {
  name: "samplilist",
  components: {
    Addsample, JPrint, TimeLine, ltree, ReserveSample
  },
  computed: {
    checkShow() {
      let show = this.level === 2 && this.$store.state.admin_info.info.id === 360
      return !show
    },
  },
  data() {
    return {
      level: app_config.level,
      page1: 1,
      count: '',
      size: 0,
      search_value: '',
      Reserve_time: 0,
      Reserve_info: '',
      drawer_visibleReserve: false,//留样
      visible: false,//退出样品弹窗
      // 定义控制抽屉显示的变量
      Addsamplelist: false,
      status: {
        0: '全部',
        1: '采集待处理',
        2: '采集中',
        3: '采集完成',
        4: '接收完成',
        8: '样品销毁',
        9: '确认领取',
        10: '拒绝领取',
        11: '等待下发采集任务',
      },
      formInline: {
        status: 0,
        name: '',
        type: ''
      },
      form: {
        remark: ''
      },
      page: 1,
      all_active: false,
      open_task: 0,
      yangpin_count: 0,
      yangpin_size: 0,
      yangpin_list: [],
      templateList: [],
      tree: [],
      info: false,
      drawer_visibleT: false,//流转时间轴
      lishiinfo: false,//流转时间轴
      active_key: -1,
      task_info: false,
      tab_active: '1',
      first_son: false,
      tree_active_id: [],
      ordertype: 0,
      sortrule: 'asc',

    }
  },
  mounted() {
    this.mountedDo()
    this.Get_tag(3)
    this.tree_show = true
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    }
  },
  methods: {

    paixuclickup(ordertype) {
      if (this.ordertype !== ordertype) {
        this.ordertype = ordertype
        this.sortrule = 'asc'
      } else {
        if (this.sortrule === 'asc') {
          this.sortrule = 'desc'
        } else {
          this.sortrule = 'asc'
        }
      }
      if (this.tab_active === '1') {
        this.$sa0.post({
          url: this.$api('Yangpinlist_new'),
          data: {
            "search_value": this.formInline.name,
            "status": this.formInline.status,
            "type": this.formInline.type,
            "page": this.page,
            ordertype: this.ordertype,
            sortrule: this.sortrule,
            task_id: this.task_info.id,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.yangpin_list = response.data.list
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      } else {
        this.$sa0.post({
          url: this.$api('样品管理：样品列表'),
          data: {
            "search_value": this.formInline.name,
            "status": this.formInline.status,
            "type": this.formInline.type,
            "page": this.page,
            ordertype: this.ordertype,
            sortrule: this.sortrule,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.yangpin_list = response.data.list
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })

      }

    },


    yangpinlist() {
      if (this.tab_active === '2') {
        this.$sa0.post({
          url: this.$api('样品管理：样品列表'),
          data: {
            "search_value": this.formInline.name,
            "status": this.formInline.status,
            "type": this.formInline.type,
            "page": this.page,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.yangpin_count = Number(response.data.count);
              this.yangpin_size = Number(response.data.size);
              this.page = Number(response.data.page);
              setTimeout(() => {
                this.yangpin_list = response.data.list.map((item) => {
                  item.active = false
                  return item
                });
              })
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      } else {
        this.$sa0.post({
          url: this.$api('Yangpinlist_new'),
          data: {
            "search_value": this.formInline.name,
            "status": this.formInline.status,
            "type": this.formInline.type,
            "page": this.page,
            task_id: this.task_info.id,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.yangpin_count = Number(response.data.count);
              this.yangpin_size = Number(response.data.size);
              this.page = Number(response.data.page);
              setTimeout(() => {
                this.yangpin_list = response.data.list.map((item) => {
                  item.active = false
                  return item
                });
              })
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      }
    },


    onMounted() {
      let qid = Number(this.$route.query.id) || false;
      console.log(qid)
      if (!qid || qid === 0) {
        this.method_id = 0
      }
    },
    showModal() {
      this.visible = true
    },
    handleOk() {
      let spec_item_ids = []
      this.yangpin_list.map((item) => {
        console.log(item)
        if (item.active) {
          spec_item_ids.push(Number(item.id))
        }
      })
      if (spec_item_ids.length < 1) {
        layer.msg('请勾选需要操作的任务')
        return
      }
      let data = {
        id: spec_item_ids.join(','),
        status: 2,
        remark: this.form.remark
      }
      console.log(JSON.stringify(data))
      this.$sa0.post({
        url: this.$api('Set_tuihuiitem'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            layer.msg(res.message)
            this.yangpinlist();
            this.visible = false
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })

    },

    // 树开始

    makeList(list, pid = []) {
      let qid = Number(this.$route.query.id) || 0;
      return list.map((item) => {
        // let
        if (qid && qid === Number(item.id)) {
          this.tree_active_id = [...pid, item.id]
          this.task_info = item
          this.item_show = false
          setTimeout(() => {
            this.item_show = true
            setTimeout(() => {
              this.yangpinlist()
            })
          })
        }
        //
        if (!this.first_son) {
          this.first_son = item;
        }
        return {
          'id': Number(item.id),
          "type": 1,
          "name": item.name,
          "children": [],
          // "children": item.child.length === 0 ? [] : this.makeList(item.child, [...pid, item.id]),
          "content": {
            "pids": pid,
            "pid": item.pid,
          }
        }
      })
    },


    treeCallback(item) {
      let info = item[item.length - 1]
      if (info.id === 'all') {
        this.$router.push({
          name: this.$route.name,
          query: {id: 0}
        })
        return
      }
      if (info.id !== 'all' && info.type === 0) return;
      this.$router.push({
        name: this.$route.name,
        query: {id: info.id}
      })
    },

    // 获取树列表
    GMethod_category_alllist(id = 0) {
      this.$sa0.post({
        url: this.$api('样品管理：任务列表'),
        data: {
          search_value: this.search_value,
          page: this.page1,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.tree_show = false
            this.tree = this.makeList(response.data.list);
            // if (id !== 0) {
            //     setTimeout(() => {
            //         this.$refs['ltreeref'].renameActive('no')
            //     }, 1000)
            // }

            if (id === 0 && this.first_son) {
              this.$router.push({
                name: this.$route.name,
                query: {
                  id: this.first_son.id,
                  page: 1,
                  status: this.formInline.status,
                  type: this.formInline.type,
                  name: this.formInline.name,
                }
              })
              return
            }
            this.tree_show = true
            if (Number(this.tab_active) === 1) {
              setTimeout(() => {
                this.$refs['ltreeref'].openGroup(this.tree_active_id)
              }, 100)
            }
            this.menuarrmaker()

            this.count = Number(response.data.count);//分页
            this.page1 = Number(response.data.page);//分页

          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    menuarrmaker() {
      this.all_menu = []
    },
    // 树结束

    showDrawerT() {
      let id = 0
      let count = 0
      this.yangpin_list.map((item, key) => {
        if (item.active) {
          this.info = item;
          id = item.id
          count++
        }
      })
      if (!id) return layer.msg('请选择样品')
      if (count !== 1) return layer.msg('只能选择一个样品')
      this.lishiinfo = false
      setTimeout(() => {
        this.edit_time1 = new Date() / 1;
        this.drawer_visibleT = true;
        this.lishiinfo = true
      })
    },
    onCloseDrawerT() {
      this.drawer_visibleT = false;
      this.yangpinlist()
    },
    clickrow(key) {
      this.$set(this.yangpin_list[key], 'active', !this.yangpin_list[key].active)
      this.itemActiveChange()
    },
    // 打印
    Get_tag(id) {
      this.$sa0.post({
        url: this.$api('Get_tag'),
        data: {
          tag_type: id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.templateList = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    PrintTAG(index) {
      let check_list = [];
      this.yangpin_list.map((item) => {
        if (item.active) {
          check_list.push({demo_num: item.in_number, demo_text: item.name})
        }
      })
      if (check_list.length === 0) {
        layer.msg('请选择要打印的样品编号')
      } else {
        //
        let template = this.templateList[index]
        let src_list = check_list.map(item => {
          template.demo_num = item.demo_num
          template.demo_text = item.demo_text
          return this.getImg(template)
        })
        console.log(check_list)
        this.$refs.doPrint.printIt(src_list);
      }
    },


    getImg(item) {
      let data = {
        i: item.demo_num,
        pw: item.paper_w,
        ph: item.paper_h,
        bs: Number(item.isshow_barcode) !== 2,
        qs: Number(item.isshow_qrcode) !== 2,
        ts: Number(item.isshow_text) !== 2,
      }

      if (Number(item.isshow_barcode) !== 2) {
        data.bw = item.barcode_w
        data.bh = item.barcode_h
        data.btm = item.barcode_top
        data.blm = item.barcode_left
      }

      if (Number(item.isshow_qrcode) !== 2) {
        data.qw = item.qrcode_w
        data.qh = item.qrcode_h
        data.qtm = item.qrcode_top
        data.qlm = item.qrcode_left
      }
      if (Number(item.isshow_text) !== 2) {
        data.tv = item.demo_text
        data.tsi = item.text_size
        data.ttm = item.text_top
        data.tlm = item.text_left
      }
      let q_arr = [];
      // let url = 'http://aaa.aaa.aaa?aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
      // ['tsi=1','ttm=1','aaa=1','aaa=1','aaa=1']
      for (let i in data) {
        q_arr.push(`${i}=${data[i]}`)
      }
      let q_str = q_arr.join('&')
      // 'aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
      let url = this.$api('qrCode') + '?' + q_str
      return url
    },


    // 打印结束

    //下发采集任务
    xiafacaijiclick() {
      let spec_item_ids = []
      this.yangpin_list.map((item) => {
        console.log(item)
        if (item.active) {
          spec_item_ids.push(Number(item.id))
        }
      })
      if (spec_item_ids.length < 1) {
        layer.msg('请勾选需要操作的任务')
        return
      }

      let data = {
        spec_item_id: spec_item_ids.join(','),
        status: 1,
      }
      console.log(JSON.stringify(data))
      this.$sa0.post({
        url: this.$api('Xiafa_specitem1'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            layer.msg(res.message)
            this.yangpinlist();
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })


    },


    //下发检验任务
    xiafajianyanclick() {
      let spec_item_ids = []
      let jc_item_count = ''
      this.yangpin_list.map((item) => {
        //console.log(item)
        if (item.active) {
          spec_item_ids.push(Number(item.id))
          jc_item_count = item.jc_item_count
          console.log(jc_item_count)
        }
      })
      if (spec_item_ids.length < 1) {
        layer.msg('请勾选需要操作的任务')
        return
      }
      if (jc_item_count === 0) {
        layer.msg('请给样品添加检验项目')
        return
      }
      let data = {
        spec_item_id: spec_item_ids.join(','),
        status: 1,
      }
      //console.log(JSON.stringify(data))
      this.$sa0.post({
        url: this.$api('Xiafa_task_check1'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            layer.msg(res.message)
            this.yangpinlist();
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })


    },


    // 退回检验任务
    tuihuijianyantaskclick() {
      let id = 0
      let count = 0
      this.yangpin_list.map((item, key) => {
        if (item.active) {
          id = item.id
          count++
        }
      })
      if (!id) return layer.msg('请选择样品')
      if (count !== 1) return layer.msg('只能选择一个样品')
      let data = {
        spec_item_id: id,
        status: 8,

      }
      console.log(JSON.stringify(data))
      this.$sa0.post({
        url: this.$api('Tuihui_task_check1'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            layer.msg(res.message)
            this.yangpinlist();
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })


    },


    // 退回采集任务
    tuihuitaskclick() {
      let spec_item_ids = []
      this.yangpin_list.map((item) => {
        console.log(item)
        if (item.active) {
          spec_item_ids.push(Number(item.id))
        }
      })
      if (spec_item_ids.length < 1) {
        layer.msg('请勾选需要操作的任务')
        return
      }

      let data = {
        spec_item_id: spec_item_ids.join(','),
        status: 11,
      }
      console.log(JSON.stringify(data))
      this.$sa0.post({
        url: this.$api('Tuihui_specitem1'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            layer.msg(res.message)
            this.yangpinlist();
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })
    },


    // 退回采集任务
    xiaohuitaskclick() {
      let spec_item_ids = []
      this.yangpin_list.map((item) => {
        console.log(item)
        if (item.active) {
          spec_item_ids.push(Number(item.id))
        }
      })
      if (spec_item_ids.length < 1) {
        layer.msg('请勾选需要操作的任务')
        return
      }


      let data = {
        spec_item_id: spec_item_ids.join(','),
        status: 8,
      }
      console.log(JSON.stringify(data))
      this.$sa0.post({
        url: this.$api('Xiaohui_specitem1'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            layer.msg(res.message)
            this.yangpinlist();
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })
    },


    // 样品接收
    samplejieshou() {
      let spec_item_ids = []
      this.yangpin_list.map((item) => {
        console.log(item)
        if (item.active) {
          spec_item_ids.push(Number(item.id))
        }
      })
      if (spec_item_ids.length < 1) {
        layer.msg('请勾选需要操作的任务')
        return
      }
      let data = {
        id: spec_item_ids.join(','),
        status: 4,
      }
      console.log(JSON.stringify(data))
      this.$sa0.post({
        url: this.$api('Set_jieshouitem'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (res) => {
            layer.msg(res.message)
            this.yangpinlist();
          },
          error: (res) => {
            layer.msg(res.message)
          }
        })
      })
    },


    itemActiveChange() {
      let do_ = true
      this.yangpin_list.map((item, key) => {
        if (!item.active) do_ = false
      })
      this.all_active = do_
    },
    allChange(e) {
      this.yangpin_list.map((item, key) => {
        this.$set(this.yangpin_list[key], 'active', this.all_active)
      })
    },
    pageJump() {
      this.$router.push({
        name: 'samplelist',
        query: {
          page: this.page,
          status: this.formInline.status,
          type: this.formInline.type,
          name: this.formInline.name,
        }
      })
    },
    pageChange(e) {
      this.pageJump()
      this.yangpinlist()
    },
    listTypeChange(e) {
      this.page = 1
      // this.pageJump()
      this.yangpinlist()

    },
    clearsearch() {
      this.formInline = {
        status: 0,
        name: '',
        type: ''
      }
      this.page = 1;
      this.pageJump() //样品管理列表
    },
    mountedDo() {
      let qid = Number(this.$route.query.id) || false;
      console.log(qid)
      if (!qid || qid === 0) {
        this.method_id = 0
      }
      this.GMethod_category_alllist(Number(this.$route.query.id) || 0);
      let task_id = Number(this.$route.params.id) || 0
      let page = Number(this.$route.query.page) || 1
      let status = Number(this.$route.query.status) || 0
      let type = Number(this.$route.query.type) || ''
      let name = this.$route.query.name || ''
      this.formInline = {
        status,
        name,
        type
      }
      this.open_task = task_id
      this.page = page;

      if (this.open_task != 0) {
        let open = this.$sa0.localStorage.get('OPEN_TASK')
        if (Number(open) === 1) {
          this.$sa0.localStorage.del('OPEN_TASK')
          this.Addsamplelist = true;
          setTimeout(() => {
            this.$refs['Addsampleref'].mountedDo(this.open_task)
          })
        }
      }


    },

    edityangpin() {
      let id = 0
      let count = 0
      this.yangpin_list.map((item, key) => {
        if (item.active) {
          id = item.id
          count++
        }
      })
      if (!id) return layer.msg('请选择样品')
      if (count !== 1) return layer.msg('只能选择一个样品')
      this.Addsamplelist = true;
      setTimeout(() => {
        this.$refs['Addsampleref'].yangpininfo(id)
      })
    },
    editCheck() {
      let id = 0
      let count = 0
      this.yangpin_list.map((item, key) => {
        if (item.active) {
          id = item.id
          count++
        }
      })
      if (!id) return layer.msg('请选择样品')
      if (count !== 1) return layer.msg('只能选择一个样品')
      this.Addsamplelist = true;
      setTimeout(() => {
        this.$refs['Addsampleref'].yangpininfo(id)
        this.$refs['Addsampleref'].sampletab = 2
      })
    },
    copyInfo() {
      let id = 0
      let count = 0
      let info = false
      this.yangpin_list.map((item, key) => {
        if (item.active) {
          id = item.id
          info = item
          count++
        }
      })
      if (!id) return layer.msg('请选择样品')
      if (count !== 1) return layer.msg('只能选择一个样品')
      this.Addsamplelist = true;
      setTimeout(() => {
        this.$refs['Addsampleref'].copyInfo(info)
      })
    },

    Changetab() {
      this.yangpinlist()
    },


    // 定义 打开抽屉时的 函数 留样
    showDrawerReserve() {
      let id = 0
      let count = 0
      let Reserve_info = []
      this.yangpin_list.map((item, key) => {
        if (item.active) {
          this.info = item;
          id = item.id
          count++
          Reserve_info = item
        }

      })
      if (!id) return layer.msg('请选择样品')
      if (count !== 1) return layer.msg('只能选择一个样品')
      this.Reserve_info = Reserve_info;
      console.log("==================================================");
      console.log(this.Reserve_info);
      this.Reserve_time = new Date() / 1;
      this.drawer_visibleReserve = true;
    },
    // 定义 关闭抽屉时的 函数
    onCloseDrawerReserve() {
      this.yangpinlist();
      this.drawer_visibleReserve = false;
      this.Reserve_info = false;
    },

    // 定义 关闭抽屉时的 函数
    onCloseaddsampleDrawer() {
      this.Addsamplelist = false;
      this.yangpinlist()
    },
    // 定义 打开抽屉时的 函数
    showsaddampleDrawer() {
      this.Addsamplelist = true;
      // this.$refs['Addsampleref'].yangpininfo()
      // this.task_info.id
      setTimeout(() => {
        this.$refs['Addsampleref'].mountedDo(this.task_info.id)
      })
    }
  }
}
</script>

<style scoped>

.samplecontainer {
  width: 100%;
  display: flex
}

.sidebar {
  width: 230px;
  border: 1px solid #eeeeee;
  height: calc(100vh - 90px);
  border-bottom: none
}

.samplemain {
  flex: 1;
  margin-left: 20px
}

.sidebartit {
  width: 100%;
  background: #f9f9f9;
  height: 42px;
  line-height: 42px;
  padding-left: 10px;
  font-weight: bold
}

table tr {
  cursor: pointer
}

.btner {
  width: 100%;
  background: #f9f9f9;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
  border: 1px solid #eeeeee;
  margin-bottom: 10px;
}

.active {
  background: #ebf6ff;
}

.btnerlist {
  cursor: pointer;
  border-right: 1px solid #eeeeee;
  height: 26px;
  line-height: 26px;
  padding: 0 6px;
  position: relative;
}

.btnerlist :last-child(1) {
  border: none
}


.btnerlist i {
  color: #999999;
  font-size: 14px;
  vertical-align: bottom;
}

.btnerlist:hover {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;

}

.btnerlist:hover i, .btnerlist:hover.fcadd {
  color: #ffffff !important
}

.isthis {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;
}

.isthis i {
  color: #ffffff
}

.print-tag {
  position: relative;
}

.print-show {
  display: none;
  position: absolute;
  top: 25px;
  left: 0;
  width: 260px;
  height: auto;
  border: solid 1px #efefef;
  background: #fff;
  padding: 15px;
  z-index: 999;
}

.btnerlist:hover .print-show {
  display: block;

}

.print-show img {
  margin-bottom: 10px;
  text-align: center
}

.ml20 {
  margin-left: -20px
}

.ant-table-column-sorter {
  display: inline-flex;
  flex-direction: column;
  margin-top: 0px;
  margin-left: 5px;
  cursor: pointer;
  width: 10px;
  vertical-align: middle;
}

.jiantou {
  color: #999999
}

.classname {
  color: #1890ff;
}

.fl {
  float: left
}

.active {
  background: #ebf6ff
}

table tr {
  cursor: pointer
}


</style>
